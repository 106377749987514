import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';

import { SnackbarConfig } from './snackbar.constants';

@Component({
  selector: 'app-snackbar-with-icon',
  templateUrl: './snackbar-with-icon.component.html',
  styleUrls: ['./snackbar-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatSnackBarModule, RouterModule],
})
export class SnackbarWithIconComponent {

  icon = {
    success: 'done',
    error: 'clear',
    errorUnexpected: 'help_outline',
  }[this.data.status];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    readonly data: SnackbarConfig,
    readonly router: Router,
  ) {
  }

}

