import { ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable, tap } from 'rxjs';
import { errorStatuses } from 'shared/constants/error-status.constants';
import {
  ControlsConfig,
  TypedAbstractControlOptions,
  TypedAsyncValidatorFn,
  TypedForm,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  TypedValidatorFn,
} from 'shared/types/form.types';
import { pipe, takeWhileTruthy } from 'shared/utils/rxjs.utils';




const validateFormRecursively = <T>(
  formGroup: TypedForm<T> | FormGroup,
  errors: {
    count: number;
  } = { count: 0 },
) => {
  Object.keysTyped(formGroup.controls).forEach(c => {
    const control = formGroup.controls[c];
    if (control instanceof FormGroup || control instanceof FormArray) {
      validateFormRecursively(control as any as TypedFormGroup<any>, errors);
    } else {
      control.markAsTouched();
      control.updateValueAndValidity();
      if (control.validator) {
        const err = control.validator(control);
        if (err) {
          console.log(`form control error for '${c}': ${err.message}`);
          errors.count++;
        }
      }
    }
  });
  return errors.count;
};

FormBuilder.prototype.groupTyped = FormBuilder.prototype.group as any;

FormBuilder.prototype.arrayTyped = FormBuilder.prototype.array as any;

FormGroup.prototype.validate = function <T>(
  context: { matSnackBar: MatSnackBar }
) {
  return pipe(
    map(() => {
      const hasErrors = !!validateFormRecursively(this);
      if (hasErrors) { context.matSnackBar.showError('Please fix all errors before continuing'); }
      return !hasErrors;
    }),
    takeWhileTruthy(),
    tap(() => this.markAsPristine()),
    map(() => this.value),
  );
};

FormGroup.prototype.displayErrorsFromApi = function <T>(
  context: { matSnackBar: MatSnackBar; changeDetectorRef: ChangeDetectorRef },
  e: typeof errorStatuses.badRequest.errorShape,
) {
  e.error.errors.filter(err => err.fieldName.toString().includes('.'))
    .forEach(error => {
      const errSegs = error.fieldName.toString().split('.');
      error.fieldName = errSegs[errSegs.length - 1] as keyof T;
    });
  const fieldErrorNames = e.error.errors.map(f => f.fieldName);
  Object.keysTyped(this.controls)
    .filter(key => fieldErrorNames.includes(key))
    .forEach(key => {
      const fieldError = e.error.errors.find(fe => fe.fieldName === key);
      if (!fieldError) { throw Error(); /* Should never happen */ }
      this.controls[key].setErrors({ message: fieldError.message });
      this.controls[key].markAsTouched();
    });
  context.changeDetectorRef.detectChanges();
  context.matSnackBar.showError('Please fix all errors before continuing');
};



declare module '@angular/forms' {
  interface FormBuilder {

    /**
     * Functionally identical to `formbuilder.group()` but with stronger types
     * providing better autocompletion support in Typescript as well as templates.
     * Note: the most upvoted issue on Angular concerns this problem.
     * https://github.com/angular/angular/issues/13721
     */
    groupTyped: <T>(
      controlsConfig: ControlsConfig<T>,
      options?: TypedAbstractControlOptions<T> | null,
    ) => TypedForm<typeof controlsConfig>;

    arrayTyped: <T>(
      controlsConfig: TypedFormControl<T>[],
      validatorOrOpts?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | TypedAbstractControlOptions<T> | null,
      asyncValidator?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null,
    ) => TypedFormArray<T>;
  }

  interface FormGroup {
    /**
     * Performs client-side validation.
     * Will not continue unless validation passes.
     *
     * @param context the component must have injected `matSnackBar: MatSnackBar`
     */
    validate<T>(context: {
      matSnackBar: MatSnackBar;
    }): Observable<T>;

    /**
     * Reports all API errors from a bad request onto the form, thus giving visual feedback to the user.
     *
     * @param context the component must have injected `matSnackBar: MatSnackBar` and `changeDetectorRef: ChangeDetectorRef`
     * @param error the bad request error received from the server.
     */
    displayErrorsFromApi(context: {
      matSnackBar: MatSnackBar;
      changeDetectorRef: ChangeDetectorRef;
    }, error: typeof errorStatuses.badRequest.errorShape): any;
  }

}

// example including a formarray
//
// const fg = this.formBuilder.groupTyped({
//   str: ['', [rules.minLength(2)]],
//   arr: [[''], []],
//   formArray: [this.formBuilder.arrayTyped(new Array<string>()), [rules.minFormArrayLength(3)]]
// });

