export { };

Array.prototype.distinct = function <T, P>(getProp?: (el: T) => P) {
  const array: Array<T> = this;
  const result = new Array<T>();
  for (const el of array) {
    if (getProp) {
      if (!result.map(r => getProp(r)).includes(getProp(el))) {
        result.push(el);
      }
    } else {
      if (!result.includes(el)) {
        result.push(el);
      }
    }
  }
  return result;
};

Array.prototype.peek = function <T>(fn: (el: T, index: number) => any) {
  const array: Array<T> = this;
  array.forEach((e, i) => fn(e, i));
  return this;
};

Array.prototype.mapToObject = function <T, K extends { toString(): string }, V>(
  getKey: (el: T, index: number) => K, getVal: (el: T, index: number) => V) {
  const array: Array<T> = this;
  const result = {} as { [key: string]: V };
  array.forEach((element, index) => result[getKey(element, index).toString()] = getVal(element, index));
  return result;
};

Array.prototype.mapToMap = function <T, K, V>(getKey: (el: T, index: number) => K, getVal: (el: T, index: number) => V) {
  const array: Array<T> = this;
  const result = new Map<K, V>();
  array.forEach((element, index) => result.set(getKey(element, index), getVal(element, index)));
  return result;
};

Array.prototype.groupBy = function <T, P extends string | number>(fn: (el: T) => P) {
  const array: Array<T> = this;
  const result: T[][] = [];
  array.forEach(e => {
    const key = fn(e);
    const arr = result.find(r => r.some(rr => fn(rr) === key));
    if (arr) {
      arr.push(e);
    } else {
      result.push([e]);
    }
  });
  return result;
};

Array.prototype.findOrThrow = function(predicate, onError) {
  const array: Array<unknown> = this;
  const found = array.find(predicate);
  if (!found) {
    if (onError) {
      onError();
    } else {
      throw new Error('Could not find array element');
    }
  }
  return found;
};

Array.prototype.filterTruthy = function <T>() {
  const array: Array<T> = this;
  return array.filter(value => !!value);
};

Array.prototype.merge = function <T, P = T>(toMerge: T[], getUniqueIdentifier: ((el: T) => P) = (el => el as any)) {
  const array: Array<T> = this;
  toMerge
    .forEach((el, i) => {
      const elementIndex = array.findIndex(e => getUniqueIdentifier(e) === getUniqueIdentifier(el));
      if (elementIndex !== -1) {
        array[elementIndex] = el;
      } else {
        array.push(el);
      }
    });
};

Array.prototype.mergeMap = function <T, P = T>(incoming: T | T[], propertyGetter: ((el: T) => P) = (el => el as any)) {
  const array: Array<T> = this;
  const toMerge = Array.isArray(incoming) ? incoming : [incoming];
  return [
    ...array.filter(el => !toMerge.some(e => propertyGetter(e) === propertyGetter(el))),
    ...toMerge,
  ];
};

Array.prototype.remove = function <T>(elementFinder: (el: T) => boolean) {
  const array: Array<T> = this;
  const indicesToRemove = array
    .map((e, i) => { if (elementFinder(e)) { return i; } return undefined; })
    .filter(e => e !== undefined) as number[];
  for (let i = indicesToRemove.length - 1; i >= 0; i--) {
    array.splice(indicesToRemove[i], 1);
  }
};

Array.prototype.replace = function <T, P = T>(element: T, getUniqueIdentifier: (el: T) => P) {
  const array: Array<T> = this;
  const toMatch = getUniqueIdentifier(element);
  const index = array.findIndex(e => getUniqueIdentifier(e) === toMatch);
  if (index === -1) {
    throw new Error('Could not find element to replace');
  }
  array[index] = element;
};

Array.prototype.replaceElseInsert = function <T, P = T>(element: T, getUniqueIdentifier: (el: T) => P) {
  const array: Array<T> = this;
  const toMatch = getUniqueIdentifier(element);
  const index = array.findIndex(e => getUniqueIdentifier(e) === toMatch);
  if (index === -1) {
    array.push(element);
  } else {
    array[index] = element;
  }
};

Array.prototype.aggregate = function <T>() {
  const array: Array<number> = this;
  return {
    sum: () => array.reduce((prev, curr) => prev + curr, 0),
    average: () => array.reduce((prev, curr) => prev + curr, 0) / array.length,
  };
};

type Aggregate<T> = T extends number ? {
  /**
   * Returns the sum of all the numbers in the array
   */
  sum: () => number;
  /**
   * Returns the average of all the numbers in the array
   */
  average: () => number;
} : Record<string, unknown>;

declare global {
  interface Array<T> {
    /**
     * Remove element(s) using the supplied function to find those elements to remove.
     *
     * @example
     * array.remove(e => e.id === 3) // remove a single element
     * @example
     * array.remove(e => e.status === 'done'); // remove multiple elements
     */
    remove(elementFinder: (el: T) => boolean): void;
    /**
     * Returns a new array with all duplicates removed.
     *
     * * If no function is supplied, elements will be compared directly.
     * This overload is useful for comparing arrays of primitives or strings, and not arrays of objects.
     *
     * * If a function is supplied, the property returned by that function will be used to compare elements
     *
     * @param getUniqueIdentifier A function to get the property which uniquely identifies elements in the array.
     *
     * @example
     * const uniqueStrings = strings.distinct();
     * @example
     * const uniquePeople = people.distinct(e => e.id);
     */
    distinct<P>(getUniqueIdentifier?: (el: T) => P): T[];
    /**
     * Replaces an array element. Will throw an error if the element could not be found.
     *
     * @param element The new element.
     * @param getUniqueIdentifier A function to get the property which uniquely identifies elements in the array.
     *
     * @example
     * currentUsers.replace(arrayElement, e => e.id);
     */
    replace<P = T>(element: T, getUniqueIdentifier: (el: T) => P): void;
    /**
     * Replaces an array element if it could be found, else inserts it if the element could not be found.
     *
     * @param element The new element.
     * @param getUniqueIdentifier A function to get the property which uniquely identifies elements in the array.
     *
     * @example
     * currentUsers.replaceElseInsert(arrayElement, e => e.id);
     */
    replaceElseInsert<P = T>(element: T, getUniqueIdentifier: (el: T) => P): void;
    /**
     * Merges the provided elements into this array using the supplied function to compare elements.
     *
     * If the incoming elements could be matched, they will replace the existing element, else they will be inserted.
     *
     * @param toMerge The elements to merge into theis array
     * @param getUniqueIdentifier A function to get the property which uniquely identifies elements in the array.
     *
     * @example
     * currentUsers.merge(array, e => e.id);
     */
    merge<P = T>(toMerge: T[], getUniqueIdentifier: (el: T) => P): void;
    /**
     * Returns a new array which is a merge of this array and the provided element(s).
     *
     * If the incoming element(s) could be matched, they will replace the existing element(s), else they will be inserted.
     *
     * @param toMerge The elements to merge into the returned array.
     * @param getUniqueIdentifier A function to get the property which uniquely identifies elements in the array.
     *
     * @example
     * const merged = currentUsers.merge(newUsersWhichMayContainDuplicates, u => u.id);
     */
    mergeMap<P = T>(toMerge: T | T[], getUniqueIdentifier: (el: T) => P): T[];
    /**
     * Can be used to perform some side-effect using each array element,
     * for example, this function can be used to log each element value.
     *
     * @example
     * array.peek(console.log);
     */
    peek(fn: (element: T, index: number) => any): T[];
    /**
     * Converts this array to an object.
     *
     * @example
     * const arrayOfUsers = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }];
     * const idsToNames = arrayOfUsers.mapToObject(e => e.id, e => e.name); // { 1: 'John', 2: 'Jane' }
     */
    mapToObject<K extends { toString(): string }, V>(
      getKey: (element: T, index: number) => K, getVal: (element: T, index: number) => V): { [key: string]: V };
    /**
     * Converts this array to an ES6 Map
     *
     * @example
     * const mapOfIdsToNames = array.mapToMap(e => e.id, e => e.name);
     */
    mapToMap<K, V>(getKey: (element: T, index: number) => K, getVal: (element: T, index: number) => V): Map<K, V>;
    /**
     * Groups this array by one of its properties
     */
    groupBy<P extends string | number>(getProp: (el: T) => P): T[][];
    /**
     * Functionally identically to the Array.find() method except that this is
     * guaranteed to either return an element or throw an error if no element could be found.
     */
    findOrThrow(predicate: (value: T, index: number, obj: T[]) => unknown, onError?: () => unknown): T;
    /**
     * Filters the array for all truthy values.
     * This will also correctly 'type' each element of the resulting array so that it is not null.
     */
    filterTruthy(): NonNullable<T>[];
    /**
     * Allows performing aggregates of the array
     */
    aggregate(): Aggregate<T>;
  }

}

