import { Location } from '@angular/common';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { errorStatuses } from 'shared/constants/error-status.constants';
import { OrganizationId, ProjectId, ScheduleId } from 'shared/constants/id.constants';
import { PossibleRouteParamsMap } from 'shared/constants/route.constants';


Object.defineProperty(Router.prototype, 'observeParam', {
  // eslint-disable-next-line object-shorthand
  get: function <T extends keyof PossibleRouteParamsMap>() {
    const router = this;
    return new Proxy({}, {
      get: (t: unknown, param: T) =>
        new (class CustomSubject extends Observable<PossibleRouteParamsMap[T]> {
          constructor() {
            super(observer => {
              observer.next(getRouteParam(router.routerState.snapshot.root, param));
              const routerSubscription = router.events.pipe(
                filter(e => e instanceof NavigationEnd),
                map(() => getRouteParam(router.routerState.snapshot.root, param)),
                distinctUntilChanged(),
                tap(paramValue => observer.next(paramValue as any)),
              ).subscribe();
              return () => routerSubscription.unsubscribe();
            });
          }
        })()
    });
  }
});

Router.prototype.observeUrl = function() {
  const router: Router = this;
  return new (class CustomSubject extends Observable<string> {
    constructor() {
      super(observer => {
        observer.next(router.url);
        const routerSubscription = router.events.pipe(
          filter(e => e instanceof NavigationEnd || e instanceof NavigationCancel),
          distinctUntilChanged(),
          tap(() => observer.next(router.url)),
        ).subscribe();
        return () => routerSubscription.unsubscribe();
      });
    }
  })();
};

Router.prototype.observeIsNavigating = function() {
  const router: Router = this;
  return new (class CustomSubject extends Observable<boolean> {
    constructor() {
      super(observer => {
        observer.next(false);
        const routerSubscription = router.events.pipe(
          distinctUntilChanged(),
          tap(e => {
            if (e instanceof NavigationStart) {
              observer.next(true);
            } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
              observer.next(false);
            }
          }),
        ).subscribe();
        return () => routerSubscription.unsubscribe();
      });
    }
  })();
};

Router.prototype.navigateNoHistory = function(activatedRoute: ActivatedRoute, commands: string[]): Promise<any> {
  const router: Router = this;
  router.navigate(commands, { relativeTo: activatedRoute, skipLocationChange: true })
    .then(() => window.history.replaceState(null, '', router.url));
  return router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    take(1),
  ).toPromise();
};

Router.prototype.navigateByUrlNoHistory = function(url: string): Promise<any> {
  const router: Router = this;
  router.navigateByUrl(url, { /* Removed unsupported properties by Angular migration: relativeTo. */ skipLocationChange: true })
    .then(() => window.history.replaceState(null, '', router.url));
  return router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    take(1),
  ).toPromise();
};

Object.defineProperty(Router.prototype, 'param', {
  // eslint-disable-next-line object-shorthand
  get: function <T extends keyof PossibleRouteParamsMap>() {
    const router = this;
    return new Proxy({}, {
      get: (t: unknown, paramName: T) => getRouteParam(router.routerState.snapshot.root, paramName) as PossibleRouteParamsMap[T],
    });
  }
});

const getRouteParam = <T extends keyof PossibleRouteParamsMap>(snapshot: ActivatedRouteSnapshot, param: T) => {
  const p = getRouteParams(snapshot)[param] as any;
  const paramOfCorrectType = isNaN(p) ? p : +p;
  return paramOfCorrectType as PossibleRouteParamsMap[T];
};

const getRouteParams = (snapshot: ActivatedRouteSnapshot) => {
  // Walk up the route tree
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  // Walk down the route tree and accumulate a map of route params
  const result = {} as { [key in keyof PossibleRouteParamsMap]: string };
  while (!!snapshot.firstChild) {
    Object.assign(result, snapshot.firstChild.params);
    snapshot = snapshot.firstChild;
  }
  return result;
};

/**
 * Redirects to the error page without changing the route.
 * Usually this will be called when there are errors resolving data in the route resolver.
 */
Router.prototype.navigateToErrorPage = function(args) {
  this.navigateByUrl(args.errorUrl + '?code=' + args.errorCode + (args.instruction ? '&instruction=' + args.instruction : ''), {
    replaceUrl: false, skipLocationChange: true,
  })
  .then(() => args.location.replaceState(args.requestedUrl));
};

declare module '@angular/router' {
  interface Router {
    /**
     * Observes changes to a route once it has completed navigation
     */
    observeUrl: () => Observable<string>;
    /**
     * Publishes true or false depending on whether or not the router is currently navigating
     */
    observeIsNavigating: () => Observable<boolean>;
    /**
     * Observes changes to the provided route param
     */
    observeParam: { [key in keyof PossibleRouteParamsMap]: Observable<PossibleRouteParamsMap[key]> };
    /**
     * Similar to router.navigate() except that this should be used when navigating within tabs.
     * This function ensures that a navigation will not be pushed onto the route history.
     * This ensures that clicking the back button doesn't navigate the user to the previously selected tab.
     */
    navigateNoHistory: (activatedRoute: ActivatedRoute, commands: string[]) => Promise<Event>;
    /**
     * Similar to router.navigateByUrl() except that this should be used when navigating within tabs.
     * This function ensures that a navigation will not be pushed onto the route history.
     * This ensures that clicking the back button doesn't navigate the user to the previously selected tab.
     */
    navigateByUrlNoHistory: (url: string) => Promise<Event>;
    /**
     * Allows you to synchronously get the current route parameter value for the provided parameter name
     */
    param: { [key in keyof PossibleRouteParamsMap]: PossibleRouteParamsMap[key] };
    /**
     * Redirects to the error page without changing the route.
     * Usually this will be called when there are errors resolving data in the route resolver.
     */
    navigateToErrorPage(arg: {
      requestedUrl: string;
      errorUrl: string;
      errorCode: typeof errorStatuses.$ids[0];
      instruction?: string;
      location: Location;
    }): void;
  }
}
