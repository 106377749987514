export {};

String.prototype.$uuid = function() {
  let result;
  let i;
  let j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) {
      result = result + '-';
    }
    i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
};

declare global {
  // eslint-disable-next-line id-blacklist
  interface String {
    $uuid: () => string;
  }
}
