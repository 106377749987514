<mat-icon class="icon"
  [class.is-error]="data.status !== 'success'">
  {{icon}}
</mat-icon>
<div class="message">
  {{this.data.message}}
</div>
<div class="link"
  *ngIf="data.navTo"
  [routerLink]="data.navTo">
  View
</div>