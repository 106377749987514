export { };

export type ValueOf<T> = T[keyof T];
Object.keysTyped = <T extends object>(o: T) => Object.keys(o) as Array<keyof T>;
Object.valuesTyped = <T extends object>(o: T) => Object.values(o) as Array<ValueOf<T>>;
Object.entriesTyped = <T extends object>(o: T) => Object.entries(o) as Entries<T>;
Object.deepFreeze = <T extends object>(o: T) => deepFreeze<T>(o);
Object.prune = <T extends Record<string, any>>(o: T) => Object.entriesTyped(o)
  .filter(([, val]) => val != null)
  .mapToObject(([key]) => key, ([, val]) => val) as T;
export const deepFreeze = <T extends object>(o: T): T => {
  Object.freeze(o);
  if (o === null || 0 === undefined) { return o; }
  (Object.getOwnPropertyNames(o) as Array<keyof T>).forEach(prop => {
    if (o.hasOwnProperty(prop)
      && o[prop] !== null
      && (typeof (o[prop]) === 'object' || typeof (o[prop]) === 'function')
      && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop] as any);
    }
  });
  return o;
};



type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

declare global {
  interface Object {
    keysTyped: <T extends object>(o: T) => Array<keyof T>;
    valuesTyped: <T extends object>(o: T) => Array<ValueOf<T>>;
    entriesTyped: <T extends object>(o: T) => Entries<T>;
    deepFreeze: <T extends object>(o: T) => T;
    prune: <T extends Record<string, any>>(o: T) => T;
  }
}
