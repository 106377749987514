import { ActivatedRouteSnapshot } from '@angular/router';
import { PossibleRouteParamsMap } from 'shared/constants/route.constants';

Object.defineProperty(ActivatedRouteSnapshot.prototype, 'param', {
  // eslint-disable-next-line object-shorthand
  get: function<T extends keyof PossibleRouteParamsMap>() {
    const snapshot: ActivatedRouteSnapshot = this;
    return new Proxy({}, {
      get: (t: any, paramName: T) => getRouteParam(snapshot, paramName) as PossibleRouteParamsMap[T],
    });
  }
});

ActivatedRouteSnapshot.prototype.getParams = function() {
  return getRouteParams(this as ActivatedRouteSnapshot);
};

const getRouteParam = <T extends keyof PossibleRouteParamsMap>(snapshot: ActivatedRouteSnapshot, param: T) => {
  const p = getRouteParams(snapshot)[param] as any;
  const paramOfCorrectType = isNaN(p) ? p : +p;
  return paramOfCorrectType as PossibleRouteParamsMap[T];
};

const getRouteParams = (snapshot: ActivatedRouteSnapshot) => {
  // Walk up the route tree
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  // Walk down the route tree and accumulate a map of route params
  const result = {} as {[key in keyof PossibleRouteParamsMap]: string};
  while (!!snapshot.firstChild) {
    Object.assign(result, snapshot.firstChild.params);
    snapshot = snapshot.firstChild;
  }
  return result;
};

declare module '@angular/router' {
  interface ActivatedRouteSnapshot {
    /**
     * Allows you to synchronously get the current route parameter value for the provided parameter name
     */
    param: PossibleRouteParamsMap;
    getParams(): {[key in keyof PossibleRouteParamsMap]: string};
  }
}
