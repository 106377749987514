import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialog, Instance } from 'shared/types/misc.types';



MatDialog.prototype.openCustom= function(component, config: any) {
  const dialog: MatDialog = this;
  return dialog.open(component, Object.assign({
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    panelClass: ['global-dialog'],
    closeOnNavigation: true,
  }, config || {}));
};

declare module '@angular/material/dialog' {
  interface MatDialog {
    openCustom<T extends CustomDialog<unknown, unknown>>(
      component: ComponentType<T>,
      ...rest: (Instance<typeof component>['data'] extends void ? [arg2?: MatDialogConfig<unknown>]
      : [arg2: MatDialogConfig<unknown> & { data: Instance<typeof component>['data'] }])
    ): MatDialogRef<T, Instance<typeof component>['result']>;

  }
}
