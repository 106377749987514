import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarWithIconComponent } from 'shared/components/snackbar-with-icon/snackbar-with-icon.component';
import { SnackbarConfig } from 'shared/components/snackbar-with-icon/snackbar.constants';


MatSnackBar.prototype.show = function(config) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(SnackbarWithIconComponent, {
    duration: config.duration || 5000,
    data: config as SnackbarConfig,
  });
};

MatSnackBar.prototype.showSuccess = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(SnackbarWithIconComponent, {
    duration: 5000,
    data: {
      status: 'success',
      message,
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showSuccessWithLink = function(message, navTo) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(SnackbarWithIconComponent, {
    duration: 5000,
    data: {
      status: 'success',
      message,
      navTo,
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showError = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(SnackbarWithIconComponent, {
    duration: 5000,
    data: {
      status: 'error',
      message
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showErrorUnexpected = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(SnackbarWithIconComponent, {
    duration: 5000,
    data: {
      status: 'errorUnexpected',
      message,
    } as SnackbarConfig,
  });
};

declare module '@angular/material/snack-bar' {
  interface MatSnackBar {
    show(config: {
      status: 'success' | 'error' | 'error unexpected';
      message: string;
      navTo?: string;
      duration?: number;
    }): MatSnackBarRef<SnackbarWithIconComponent>;
    showSuccess(message: string): MatSnackBarRef<SnackbarWithIconComponent>;
    showSuccessWithLink(message: string, navTo: string): MatSnackBarRef<SnackbarWithIconComponent>;
    showError(message: string): MatSnackBarRef<SnackbarWithIconComponent>;
    showErrorUnexpected(message: string): MatSnackBarRef<SnackbarWithIconComponent>;
  }
}
