export { };


Map.prototype.getOrThrow = function <K, V>(key: K | null | undefined) {
  const map: Map<K, V> = this;
  if (!key) { throw new Error(`Cannot get from map. Spplied key is ${key}`); }
  const found = map.get(key);
  if (!found) { throw new Error(`Map does not contain key: ${key}`); }
  return found;
};

Map.prototype.getOrNull = function <K, V>(key: K | null | undefined) {
  const map: Map<K, V> = this;
  return !key ? null : map.get(key);
};

declare global {
  interface Map<K, V> {
    /**
     * Functionally identically to the Map.get() method except that this is guaranteed to either return a value or throw an error.
     */
    getOrThrow: (key: K | null | undefined) => V;
    /**
     * Functionally identically to the Map.get() method except that this is guaranteed to either return a value or null.
     */
    getOrNull: (key: K | null | undefined) => V | null;
  }
}
